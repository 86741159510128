header {
  @include box-sizing(border-box);
  @include clearfix;

  margin-left: 10px;
  margin-right: 10px;

  @include media ($large-screen) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include media (max-width $full-screen) {
    @include clearfix;
  }

  .logo {
    text-align: center;
    width: 100%;
    padding: 10px 0 0;

    h1 {
      line-height: 0;
      margin: 0;
    }

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      max-height: 180px;
    }

    @include media ($medium-screen) {
      float: left;
      text-align: left;
      width: 45%;
      padding: 10px 0;
    }

    @include media ($full-screen) {
      width: 30%;
    }
  }

  .social {
    img {
      @include border-radius(5px);

      box-shadow: black 2px 2px 5px;
      height: 45px;
      margin: 2px;

      @include media ($medium-screen) {
        height: 36px;
      }
    }
  }

  .login {
    a {
      font-weight: 600;
      text-shadow: 1px 1px 2px $mid-shadow-colour;
    }
  }

  .rightheader {
    text-align: center;
    width: 100%;

    h2 {
      font-size: 2.8em;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 5px;
      margin-top: 0;
      text-shadow: 1px 1px 2px $mid-shadow-colour;
    }

    h3 {
      font-size: 2.5em;
      font-weight: 100;
      font-style: italic;
      text-align: center;
      line-height: 1;
      margin: 0;
      text-shadow: 1px 1px 2px $mid-shadow-colour;
    }

    h4 {
      font-size: 1.5em;
      font-weight: 400;
      line-height: .8;
      margin-bottom: 10px;
      margin-top: 10px;
      text-shadow: 1px 1px 2px $shadow-colour;
    }

    @include media ($medium-screen) {
      float: left;
      padding-top: 5px;
      text-align: center;
      width: 55%;

      h2 {
        margin: 0;
      }

      h4 {
        font-weight: 400;
        line-height: .8;
        margin-top: 0;
        margin-bottom: 5px;
        margin-right: 5px;
      }

      .callback {
        @include border-radius(5px);
        font-size: .8em;
        padding: 5px 15px;
        margin-right: 5px;
      }
    }

    @include media ($large-screen) {

      h3 {
        font-size: 2.8em;
      }

      h2 {
        font-size: 3.4em;
        margin-bottom: 0;
      }
    }

    @include media ($full-screen) {
      width: 70%;
      //height: 155px;
      text-align: right;

      h3 {
        text-align: right;
        font-size: 3.2em;
        margin-left: 0;
        margin-right: 0;
        margin-top: 3px;
      }
    }
  }
}

