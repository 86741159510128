$navigation-submenu-padding: .5em;
$navigation-submenu-width: 17em;

.nav-wrap {
  @include clearfix;
  @include box-sizing(border-box);

  position: relative;
  z-index: 9999;
  margin: 0 10px;

  @include media ($large-screen) {
    margin-left: 20px;
    margin-right: 20px;
  }


  .navigation {

    a {
      text-decoration: none;
    }

    @include border-radius($border-radius);
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .4) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 55%),
            radial-gradient(darken($navigation-background, 1%), darken($navigation-background, 15%)));
    box-shadow: black 2px 2px 7px;
    background-color: $navigation-background;
    font-size: 1.2em;
    min-height: $navigation-height;
    text-align: center;
    //text-transform: uppercase;
    //width: 100%;
    z-index: 9999;


    @include media ($full-screen) {
      float: left;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    .navigation-wrapper {
      @include clearfix;
      @include outer-container;
      position: relative;
      z-index: 9999;
    }

    // Mobile view

    .navigation-menu-button {
      color: $navigation-color;
      display: block;
      float: right;
      font-weight: 700;
      line-height: $navigation-height;
      margin: 0;
      padding-right: 1em;
      text-transform: uppercase;

      @include media ($large-screen) {
        display: none;
      }

      &:focus,
      &:hover {
        color: $navigation-color-hover;
      }
    }

    // Nav menu

    nav {
      float: none;
      min-height: $navigation-height;
      z-index: 9999;
    }

    ul.navigation-menu {
      -webkit-transform-style: preserve-3d; // stop webkit flicker
      clear: both;
      display: none;
      margin: 0 auto;
      overflow: visible;
      padding: 0;
      width: 100%;
      z-index: 9999;
      position: relative;

      &.show {
        display: block;
      }

      @include media ($large-screen) {
        display: inline;
        margin: 0;
        padding: 0;
      }
    }

    // The nav items

    ul li.nav-link {
      background: $navigation-background;
      display: block;
      line-height: $navigation-height;
      overflow: hidden;
      text-align: right;
      padding-right: 20px;
      width: 100%;
      z-index: 9999;

      @include media ($large-screen) {
        background: transparent;
        display: inline;
        line-height: $navigation-height;
        text-align: center;
        text-decoration: none;
        padding: 0;
      }

      a {
        @include transition(all 0.3s ease-in-out);

        color: $navigation-color;
        display: inline-block;
        font-weight: 400;
        text-shadow: 1px 1px 1px rgba(0,0,0,.8);

        @include media ($large-screen) {
          @include radial-gradient-animation(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%), 0.3s);

          margin: 0;
          padding: 0;
          width: 33.33%;
        }

        &.active-nav-item,
        &:focus,
        &:hover {
          color: #000;
          font-weight: 500;
          text-shadow: 1px 1px 1px #ccc;

          @include media ($large-screen) {
            color: $navigation-color-hover;
            background-color: $navigation-background-hover;
          }
        }

        &.active-nav-item,
        &:focus {
          @include media ($large-screen) {
            @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
                    radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));

            background-color: $navigation-background-hover;
          }
        }
      }

      &:first-child {
        a {
          &.active-nav-item,
          &:focus,
          &:hover,
          &:before {
            @include border-left-radius($border-radius);
          }
        }
      }

      &:last-child {
        a {
          &.active-nav-item,
          &:focus,
          &:hover,
          &:before {
            @include border-right-radius($border-radius);
          }
        }
      }
    }

    ul li.nav-link:last-child {
      @include media ($large-screen) {
      }
    }

    // Sub menus

    li.more.nav-link {
      padding-right: 0;

      > ul > li:first-child a  {
        padding-top: 0;
      }

      a {
        @include transition(all 0.3s ease-in-out);

        margin-right: $navigation-submenu-padding;
      }

      > a {
        padding-right: 0.6em;
      }

      > a:after {
        @include transition(all 0.3s ease-in-out);
        @include position(absolute, auto -0.4em auto auto);
        color: $navigation-color;
        content: "\25BE";
      }

      > a {
        &.active-nav-item,
        &:focus,
        &:hover {

          &:after {
            color: white;
          }
        }
      }


      @include media($large-screen) {

        a {
          margin-right: 0;
        }

        > a {
          padding-right: 0;
        }

        > a:after {
          @include position(absolute, auto .2em auto auto);
          color: $navigation-color;
          content: "\25BE";
        }

        > a {
          &.active-nav-item,
          &:focus,
          &:hover {

            &:after {
              color: white;
            }
          }
        }
      }
    }

    li.more {

      overflow: visible;
      padding-right: 0;

      a {
        padding-right: $navigation-submenu-padding;
      }

      > a {
        padding-right: 1.6em;
        position: relative;

        @include media($large-screen) {
          margin-right: $navigation-submenu-padding;
        }

        &:after {
          content: "›";
          font-size: 1.2em;
          position: absolute;
          right: $navigation-submenu-padding / 2;
        }
      }

      &:focus > .submenu,
      &:hover > .submenu {
        max-height: 2000px;
      }

      @include media($large-screen) {
        padding-right: $navigation-submenu-padding;
        position: relative;
      }
    }

    ul.submenu {
      @include transition(all 0.5s ease-in-out);

      max-height: 0;
      padding-left: 0;

      @include media($large-screen) {
        overflow: hidden;
        left: -$navigation-submenu-padding;
        position: absolute;
        top: 1.5em;
      }

      .submenu {
        @include media($large-screen) {
          left: $navigation-submenu-width - 0.2em;
          top: 0;
        }
      }

      li {
        display: block;
        padding-right: 0;
        line-height: 1;

        @include media($large-screen) {
          line-height: $navigation-height / 1.3;
          font-size: 0.8em;
          &:first-child > a {
            @include border-top-radius($border-radius);

            &.active-nav-item,
            &:focus,
            &:hover,
            &:before {
              @include border-top-radius($border-radius);
            }
          }

          &:last-child > a {
            @include border-bottom-radius($border-radius);

            &.active-nav-item,
            &:focus,
            &:hover,
            &:before {
              @include border-bottom-radius($border-radius);
            }
          }
        }

        a {
          background-color: darken($navigation-background, 3%);
          display: inline-block;
          text-align: right;
          text-decoration: none;
          width: 100%;
          padding: 5px 0;


          @include media($large-screen) {
            @include background-image(linear-gradient(hsla(0, 100%, 100%, .4) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 55%),
                    radial-gradient(darken($navigation-background, 1%), darken($navigation-background, 15%)));

            background-color: $navigation-background;
            padding-left: $navigation-submenu-padding;
            text-align: left;
            width: $navigation-submenu-width;
          }
        }
      }
    }
  }
}
