.downloads {
  text-align: center;

  span,
  a {
    @include transition(all 0.3s ease-in-out);
    font-weight: 400;
    color: $alt-colour;
    text-shadow: 1px 1px 1px rgba(0,0,0,1);

    &:hover {
      color: lighten($alt-colour, 15%);
    }
  }

  article {
    @include border-radius($border-radius);
    @include background-image(linear-gradient(320deg, darken($alt-colour, 16.6%) 0%, lighten($alt-colour, 9.5%) 49%, lighten($alt-colour, 9.5%) 51%, darken($alt-colour, 16.6%) 100%));

    box-shadow: black 2px 2px 7px;
    display: inline-block;
    background-color: lighten($alt-colour, 15%);
    padding: 10px;

    width:120px;
    margin: 10px;

    @include media ($medium-screen) {
      width:150px;
    }

    @include media ($large-screen) {
      width:200px;
    }

    @include media ($full-screen) {
      width:220px;
    }

    a {
      color: $main-colour;

      &:hover {
        color: lighten($main-colour, 20%);
      }
    }


    img {
      box-shadow: none;
    }

    .equalise {
      float: left;
    }
  }
}

