.clearfix {
  @include clearfix;
}

.hero-border {
  @include clearfix;
  @include border-radius($border-radius);
  box-shadow: black 2px 2px 7px;
  margin: 10px;

  @include media ($large-screen) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.slidecontainer {
  @include clearfix;
}

.cycle-pager {
  overflow: hidden;
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  z-index: 999;

  span {
    color: #ddd;
    cursor: pointer;
    display: inline-block;
    font-size: 50px;
    height: 16px;
    width: 16px;
  }

  .cycle-pager-active {
    color: $main-colour;
  }

  > * {
    cursor: pointer;
  }
}

.cycle-prev,
.cycle-next {
  position: absolute;
  top: 0;
  width: 20%;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 999;
  height: 100%;
  cursor: pointer;

}

.cycle-prev {
  left: 0;
  background: url(/images/left.png) 5% 50% no-repeat;
}

.cycle-next {
  right: 0;
  background: url(/images/right.png) 95% 50% no-repeat;
}

.cycle-prev:hover,
.cycle-next:hover {
  opacity: .7;
  filter: alpha(opacity=70)
}

.disabled {
  opacity: .5;
  filter: alpha(opacity=50);
}

@keyframes priceemphasis {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: .2;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.hero {
  $hero-background-top: #7F99BE;
  $hero-background-bottom: #20392B;
  $hero-color: white;
  $gradient-angle: 10deg;
  $hero-image1: '/images/slider1.jpg';
  $hero-image2: '/images/slider2.jpg';
  $hero-image3: '/images/slider3.jpg';
  height: inherit;
  width: 100%;

  .hero-img1 {
    background: url($hero-image1);
    .hero-copy {
      margin: .1em 5px 0;
      @include media($large-screen) {
        margin-top: 5em;
        max-width: 80%;
      }
    }
    h2 {
      color: red !important;
      animation: priceemphasis 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease;
    }
  }

  .hero-img2 {
    background: url($hero-image2);
    h2 {
      line-height: 1;
    }

    .hero-copy {
      margin: 0 5px;
      @include media($large-screen) {
        float: right;
        margin: 1em 1em 0 0;
        max-width: 80%;
      }
    }
  }

  .hero-img3 {
    background: url($hero-image3);
    h2 {
      line-height: 1;
    }

    .hero-copy {
      margin: 0 5px;
      @include media($large-screen) {
        margin: 1em 1em 0 1em;
        max-width: 70%;
      }
    }
  }

  .hero-img1,
  .hero-img2,
  .hero-img3 {
    @include border-radius($border-radius);
    @include clearfix;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .hero-inner {
    @include outer-container;
    @include clearfix;
    color: $main-colour;
    text-align: center;
    height: 280px;

    @include media($large-screen) {
      height: 320px;
    }

    .hero-copy {
      @include border-radius($border-radius);
      background-color: rgba(255, 255, 255, .50);
      display: inline-block;
      padding: 10px 5px 5px;

      h2 {
        //text-shadow: 2px 2px 1px rgba(0,0,0,.80);
        text-shadow: -1px -1px #ccc, 1px 1px #ccc, -1px 1px #ccc, 1px -1px #ccc, 3px 3px 2px rgba(0, 0, 0, .80);
        color: $alt-colour;
        font-size: 2.5em;
        margin-bottom: .1em;

        &.smaller {
          font-size: 2em;
        }

        @include media($large-screen) {
          font-size: 3em;

          &.smaller {
            font-size: 3em;
          }
        }
      }

      p {
        //text-shadow: 1px 1px 0 rgba(255,255,255,1);
        text-shadow: -1px -1px #000, 1px 1px #000, -1px 1px #000, 1px -1px #000, 2px 2px 1px rgba(0, 0, 0, .80);
        font-weight: 600;
        line-height: 1.4em;
        margin: auto;

        &.offerterms {
          font-size: .8em;
        }

        .large {
          font-size: 1.6em;
        }

        @include media($large-screen) {
          font-size: 1.4em;
          max-width: 80%;
        }
      }

      img {
        max-width: 400px;
        width: 80%;
      }
    }
  }

  /*
  .hero-img5 {
    background-position: top;

    .hero-copy {
      background: none;

      p {
        text-shadow: 1px 1px 1px rgba(255,255,255,1);
        color: #000;

        &.offerterms {
          color: $alt-colour;
          font-size: 1.3em;
          text-shadow: 1px 1px 1px rgba(0,0,0,1);

          @include media($medium-screen) {
            font-size: 1.5em;
          }

          @include media($large-screen) {
            font-size: 2em;
          }
        }
      }
    }
  }
  */

  .heronav {
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 100%;
    z-index: 999;

    span {
      color: #ddd;
      cursor: pointer;
      display: inline-block;
      font-size: 50px;
      height: 16px;
      width: 16px;

      .cycle-pager-active {
        color: #D69746;
      }
    }

    > * {
      cursor: pointer;
    }
  }
}

.cta-button {
  @include border-radius(5px);
  @include border-color($main-colour);
  @include border-width(1px);
  @include border-style(solid);
  @include transition(all 0.3s ease-in-out);
  @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
          radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
  @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s);

  cursor: pointer;
  color: $navigation-color-hover;
  background-color: $navigation-background-hover;
  font-weight: 400;
  margin: 5px 0;
  padding: 5px 20px;
  display: inline-block;
  line-height: 1;
  text-shadow: 1px 1px 2px $dark-shadow-colour;
  box-shadow: black 2px 2px 7px;

  &:hover {
    color: darken($navigation-color-hover, 15%);
    font-weight: 500;
    text-shadow: 1px 1px 1px #ccc;
    box-shadow: black 1px 1px 3px;
  }

  &:before {
    @include border-radius(5px);
  }
}
