/* Some stylesheet reset */
.sidemenu {
  @include media ($large-screen) {
    @include span-columns(3);
  }

  /* The container */
  > ul {
    @include border-radius($border-radius);

    display: none;

    @include media ($large-screen) {
      display: block;
    }

    @include media ($full-screen) {
      font-size: 1.2em;
    }

    margin: 0 0 10px;
    padding: 0;
    vertical-align: baseline;
    line-height: 1;
    box-shadow: black 2px 2px 7px;
    position: relative;
    width: 100%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .7);
    /* The list elements which contain the links */
    li {
      display: block;
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;

      /* General link styling */
      a {
        /* Layout */
        display: block;
        position: relative;
        margin: 0;
        border-top: 1px dotted #fff;
        border-bottom: 1px dotted #d9d9d9;
        padding: 11px 20px;
        font-weight: 400;
        /* Background & effects */
        @include background-image(linear-gradient(hsla(0, 100%, 100%, .4) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 55%),
                radial-gradient(darken($navigation-background, 1%), darken($navigation-background, 15%)));
        background-color: $navigation-background;
        z-index: 9999;
        //background: linear-gradient($main-colour, darken($main-colour, 15%));
      }
      /* Rounded corners for the first link of the menu/submenus */
      &:first-child > a {
        @include border-top-radius($border-radius);
        border-top: 0;
      }
      /* Rounded corners for the last link of the menu/submenus */
      &:last-child > a {
        @include border-bottom-radius($border-radius);
        border-bottom: 0;
      }
      /* The hover state of the menu/submenu links */
      .nav-on,
      a:hover,
      &:hover > a {
        color: #ffffff;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
        @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
                radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));

        background-color: $navigation-background-hover;
        border-color: transparent;
      }
      /* Changing the color of the arrow on hover */
      a.nav-on::after,
      a:hover::after,
      &:hover > a::after {
        border-left: 4px solid #fff;
      }
      a.nav-on::before,
      a:hover::before,
      &:hover > a::before {
        border-left: 4px solid rgba(0, 0, 0, 0.25);
      }
      /* Showing the submenu when the user is hovering the parent link */
      &:hover > ul {
        top: 0;
        opacity: 1;
        z-index: 1;
      }

    }
    /* The arrow indicating a submenu */
    .has-sub > {
      a::after {
        content: "";
        position: absolute;
        top: 16px;
        right: 10px;
        width: 0;
        height: 0;
        /* Creating the arrow using borders */

        border: 4px solid transparent;
        border-left: 4px solid $alt-colour;
      }
      /* The same arrow, but with a darker color, to create the shadow effect */
      a::before {
        content: "";
        position: absolute;
        top: 17px;
        right: 10px;
        width: 0;
        height: 0;
        /* Creating the arrow using borders */

        border: 4px solid transparent;
        border-left: 4px solid #fff;
      }
    }
    /* THE SUBMENUS */
    ul {
      position: absolute;
      left: 100%;
      top: -9999px;
      padding-left: 5px;
      opacity: 0;
      width: 200px;
      /* The fade effect, created using an opacity transition */

      transition: opacity 0.3s ease-in;
    }
  }

  .menucount {
    font-size: .7em;
  }
}
