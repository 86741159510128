.carousel-wrapper {
  @include border-radius($border-radius);

  box-shadow: black 2px 2px 7px;
  margin: 10px;
  padding: $border-padding;

  @include media ($large-screen) {
    margin-left: 20px;
    margin-right: 20px;
  }

  .carousel {
    margin: 0 20px;

    img {
      @include transition(all 1.0s ease-in-out);
      @include border-radius(10px);

      box-shadow: black 2px 2px 7px;

      margin:10px 20px;
      padding: $border-padding;
      height: 150px;
    }
    .slick-center {

      img {
        @include transform(scale(1.1));
      }
    }
  }
}
