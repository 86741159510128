.bullets {
  @include clearfix();
  @include border-radius($border-radius);
  @include background-image(linear-gradient(320deg, darken($alt-colour, 16.6%) 0%, lighten($alt-colour, 9.5%) 49%, lighten($alt-colour, 9.5%) 51%, darken($alt-colour, 16.6%) 100%));


  padding: 25px 5px 10px;
  color: $main-colour;
  text-shadow: 1px 1px 2px rgba(0,0,0,1);
  margin-bottom: 15px;
  box-shadow: black 2px 2px 7px;

  ul {
    list-style-type: disc;
    padding-left: 15px;

    @include media($medium-screen) {
      @include span-columns(6);
    }

    &.elv {
      @include span-columns(12);
    }

    li {
      height: 20px;
      padding-left: 10px;
      font-size: 1.2em;
      font-weight: 400;
      line-height: 1;
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }

}
