.content {
  @include clearfix;
  font-size: .8em;
  font-weight: 300;
  padding: 0 10px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);

  p {
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  h2 {
    font-weight: 300;
    color: $alt-colour;
    margin-top: 10px;
    margin-bottom: 5px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, .50);
  }

  h3,
  h4 {
    font-weight: 400;
    margin: 0;
  }

  h6 {
    line-height: 1.4;
    font-size: 1.3em;
    font-weight: 400;
  }

  .blurb {
    text-align: center;

    a {
      @include border-radius(5px);
      @include border-color($main-colour);
      @include border-width(1px);
      @include border-style(solid);
      @include transition(all 0.3s ease-in-out);
      @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
              radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
      @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s);

      cursor: pointer;
      color: $navigation-color-hover;
      background-color: $navigation-background-hover;
      font-size: 1.8em;
      font-weight: 400;
      margin: 5px 0;
      padding: 10px 30px;
      display: inline-block;
      line-height: 1;
      text-shadow: 1px 1px 2px $dark-shadow-colour;
      box-shadow: black 2px 2px 7px;

      &:hover {
        color: darken($navigation-color-hover, 15%);
        text-shadow: 1px 1px 1px #ccc;
        box-shadow: black 1px 1px 3px;
      }

      &:before {
        @include border-radius(5px);
      }
    }
  }

  .note {
    font-size: .7em;
    font-weight: normal;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .50);
  }

  a {
    @include transition(all 0.3s ease-in-out);
    font-weight: 300;
    color: $alt-colour;

    &:hover {
      color: darken($alt-colour, 15%);
      font-weight: 400;
    }
  }

  .indent {
    margin-left: 20px;
  }

  @include media ($large-screen) {
    padding: 0 20px;

    h6 {
      font-size: 1.4em;
    }

    p {
      font-size: 1.3em;
    }
  }

  @include media ($full-screen) {
    h6 {
      font-size: 1.6em;
    }

    p {
      font-size: 1.4em;
    }
  }

  .main-col {

    p {
      font-size: 1.2em;
    }

    @include media ($large-screen) {
      @include span-columns(9);
    }
  }

  .aside {

    @include media ($large-screen) {
      @include span-columns(3);

      .openhours {
        p {
          font-size: 1em;
        }
      }
    }

    @include media ($full-screen) {

      .openhours {
        p {
          font-size: 1.3em;
        }
      }
    }
  }

  aside {
    text-align: center;

    h2 {
      text-align: left;
    }

    @include media ($large-screen) {
      text-align: left;
    }
  }

  img {
    @include border-radius($border-radius);
    box-shadow: black 2px 2px 7px;
    height: auto;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    &.fright {
      margin: 10px auto;

      @include media ($large-screen) {
        float: right;
        margin: 10px 0 10px 10px;
        max-width: 45%;
      }
    }

    &.info-fright {
      margin: 10px auto;

      @include media ($medium-screen) {
        float: right;
        margin: 10px 0 10px 10px;
        max-width: 45%;
      }
    }

    &.fleft {
      margin: 10px auto;

      @include media ($large-screen) {
        float: left;
        margin: 10px 10px 10px 0;
        max-width: 35%;
      }
    }

    &.divider {
      border: 0;
      box-shadow: none;
      padding: 0;
      margin: 0 auto 25px;
      width: 85%;
    }
  }
}

.maintxt {
  span,
  a {
    @include transition(all 0.3s ease-in-out);
    font-weight: 400;
    color: $alt-colour;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);

    &:hover {
      color: lighten($alt-colour, 15%);
    }
  }
}
