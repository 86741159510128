.signin,
.register {
  margin-bottom: 20px;

  p {
    font-size: .9em;

    @include media ($medium-screen) {
      font-size: 1.2em;
    }
  }

  h2 {
    font-size: 1.4em;

    @include media ($medium-screen) {
      font-size: 1.6em;
    }
  }

  .signin-form,
  .reg-form {

    .passwords,
    .contact-details,
    .form-wrap {
      @include clearfix();

      margin: auto;
      max-width: 600px;

      .formgroup {
        padding-bottom: 5px;
        padding-top: 0;

        @include media ($medium-screen) {
          @include span-columns(6);
          @include omega(2)
        }

        label {
          margin-bottom: 0;

          &.error {
            color: red;
            margin-top: -8px;
          }
        }
      }
    }

    .privnotes {
      display: inline;
      font-size: .9em;
    }

    .error-message {
      text-align: center;
      color: red;
    }

    .submit {
      @include clearfix();

      text-align: center;
    }

    .postcode {
      @include clearfix();

      .formgroup {
        padding-bottom: 5px;
        padding-top: 0;

        @include media ($medium-screen) {
          margin: auto;
          max-width: 300px;
        }

        label {
          margin-bottom: 0;

          &.error {
            color: red;
            margin-top: -8px;
          }
        }
      }

    }

    .name {
      @include clearfix();

      text-align: center;

      .formgroup {
        margin-left: 5px;
        margin-right: 5px;
        padding-bottom: 5px;
        padding-top: 0;
        text-align: left;

        label {
          margin-bottom: 0;

          &.error {
            color: red;
            margin-top: -8px;
          }
        }

        select {
          padding: 5px;
          width: 100%;
        }
      }

      @include media ($medium-screen) {
        .text {
          display: inline-block;
          vertical-align: top;
          width: 250px;
        }

        .select {
          display: inline-block;
          vertical-align: top;
          width: 85px;
        }
      }
    }
  }
}
