.openhours {
  max-width: 450px;
  margin: 0 auto 10px;

  p {
    padding: 5px;
    margin: 0;
  }

  .openrow {
    &:nth-child(odd) {
      background-color: lighten($main-colour, 20%);
    }

  }

  .day {
    display: inline-block;
    width: 40%;
  }

  .time {
    display: inline-block;
    width: 55%;
  }
}
