.modal {
  $base-border-color: #dcdcdc !default;
  $base-border-radius: 3px !default;
  $base-background-color: #fff !default;
  $base-font-size: 1em !default;
  $base-line-height: 1.5em !default;
  $action-color: #477dca !default;
  $dark-gray: #333 !default;
  $light-gray: #ddd !default;
  $medium-screen: 40em !default;
  $large-screen: 53.75em !default;
  $base-font-color: $dark-gray !default;
  $modal-padding: 3em;
  $modal-background: $base-background-color;
  $modal-close-color: $light-gray;
  $modal-image-height: 135px;
  $modal-image-width: $modal-image-height;
  $modal-trigger-image-width: 300px;

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  label img {
    border-radius: $modal-trigger-image-width / 2;
    display: block;
    max-width: $modal-trigger-image-width;
  }

  .modal-state {
    display: none;
  }

  .modal-trigger {
    @include border-radius(7px);
    @include transition(all 0.3s ease-in-out);
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
            radial-gradient(darken($main-colour, 5%), darken($main-colour, 20%)));
    @include radial-gradient-animation(darken($main-colour, 10%), darken($main-colour, 25%), 0.3s);

    color: $navigation-color-hover;
    background-color: $main-colour;
    display: inline-block;
    font-weight: 600;
    margin: 0;
    padding: 5px 20px;
    line-height: 1.6;
    text-shadow: 1px 1px 2px $dark-shadow-colour;
    box-shadow: black 2px 2px 5px;

    &:hover {
      color: darken($navigation-color-hover, 30%);
      box-shadow: black 1px 1px 3px;
    }

    &:before {
      @include border-radius(7px);
    }

    @include media ($medium-screen) {
      font-size: 1.4em;
    }
  }

  .modal-fade-screen { // overlay
    @include position(fixed, 0);
    background-color: rgba(#000, 0.85);
    opacity: 0;
    padding-top: 0.6em;
    text-align: left;
    transition: opacity 0.25s ease;
    visibility: hidden;
    z-index: 99999999999;

    @include media($large-screen) {
      padding-top: 10em;
    }

    .modal-bg {
      @include position(absolute, 0);
      cursor: pointer;
    }
  }

  .modal-close {
    @include position(absolute, ($modal-padding /2) ($modal-padding /2) null null);
    @include size(1.5em);
    background: $modal-background;
    cursor: pointer;

    &::after,
    &::before {
      @include position(absolute, 3px 3px 0 50%);
      @include size(0.15em 1.5em);
      background: $modal-close-color;
      content: "";
      display: block;
      margin: -3px 0 0 -1px;
      transform: rotate(45deg);
    }

    &:hover::after,
    &:hover::before {
      background: darken($modal-close-color, 10%);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }

  .modal-inner {
    color: #696969;
    background: $modal-background;
    border-radius: $border-radius;
    margin: auto;
    margin-top: 0;
    max-height: 95%;
    overflow: auto;
    position: relative;
    transition: opacity 0.25s ease;
    width: 95%;
    text-shadow: none;


    @include media($medium-screen) {
      max-width: 500px;
    }

    select {
      display: inline-block;
    }

    .payment-errors {
      display: block;
      text-align: center;
      font-weight: 600;
      color: red;
    }

    .security-info {
      @include border-color(darken(#d9edf7, 20%));

      color: #3a87ad;
      background-color: #d9edf7;
      text-align: center;
      line-height: 1.4;
      font-size: 0.9em;
      box-shadow: none;

      h4 {
        margin-bottom: 5px;
      }
    }

    .stripe-label {
      border-radius: 3px;
      color: #ffffff;
      font-weight: 600;
      background-color: #3a87ad;
      padding: 0 6px 2px;
    }

    .modal-header {
      @include clearfix();

      padding: 1.5em 1.5em 0;
      margin-bottom: 1em;
      text-align: center;

      img {
        max-height: 50px;
      }

      @include media($medium-screen) {
        padding: 3em 3em 0;

        img {
          max-height: 40px;
          float: right;
        }

        h3 {
          float: left;
          margin-top: 10px;
        }
      }
    }

    .modal-body {
      padding: 0 1.5em;
      @include media($medium-screen) {
        padding: 0 3em;
      }
    }

    .modal-footer {
      border-top: #cccccc solid 1px;
      background-color: #EEEEEE;
      text-align: center;
      padding: 1.6em 2em 2em;

      button {
        @include border-radius(5px);
        @include transition(all 0.3s ease-in-out);
        @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
                radial-gradient(darken($main-colour, 5%), darken($main-colour, 20%)));
        @include radial-gradient-animation(darken($main-colour, 10%), darken($main-colour, 25%), 0.3s);

        color: $navigation-color-hover;
        background-color: $main-colour;
        font-size: 1.4em;
        font-weight: 600;
        padding: 5px 20px;
        line-height: 1.6;
        text-shadow: 1px 1px 2px $dark-shadow-colour;
        box-shadow: black 2px 2px 5px;

        &:hover {
          color: darken($navigation-color-hover, 30%);
          box-shadow: black 1px 1px 3px;
        }

        &:before {
          @include border-radius(5px);
        }
      }
    }

  }

  .modal-state:checked + .modal-fade-screen {
    opacity: 1;
    visibility: visible;
  }

  .modal-state:checked + .modal-fade-screen .modal-inner {
    top: 0.5em;
  }
}

.modal-open {
  overflow: hidden;
}