.grid {

  p {
    text-align: center;
    line-height: 1.2;
    margin: 0;
    padding: 0 5px;
  }

  h4 {
    text-align: center;
    color: $alt-colour;
    font-size: 1.8em;
    font-weight: 300;
    padding: 0;
    margin: 0 0 5px;
  }

  a {
    display: block;
    text-align: right;
    text-shadow: 1px 1px 1px #000;
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    padding: 0;

    &:hover {
      font-weight: 400;
    }
  }

  img {
    padding: 0;
    margin: 0;
  }

  h5 {

    span,
    label {
      color: $alt-colour;
      text-shadow: 1px 1px 1px black;
      display: inline;
    }

    label {
      cursor: pointer;

      &:hover {
        color: darken($alt-colour, 15%);
      }
    }
  }
}

article {

  .box-content {
    @include border-bottom-radius(9px);
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .1) 0%, hsla(0, 100%, 100%, .3) 100%, transparent 2%),
            radial-gradient(darken($navigation-background, 5%), darken($navigation-background, 15%)));

    background-color: $nav-footer-background-color;
    padding: 10px 5px;
  }

  &.col2 {

    a {
      text-align: center;
      margin: 0 0 10px;
    }

    @include media ($medium-screen) {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  &.col3 {
    @include media ($medium-screen) {
      @include span-columns(4);
      @include omega(3n);
    }
  }

  &.col4 {
    @include media ($large-screen) {
      @include span-columns(3);
      @include omega(4n);
    }
  }

  &.extra-col {
    @include media (min-width $medium-screen max-width $large-screen) {
      @include span-columns(12);
      @include omega(1n);
      width: 100%;
    }
  }
}
