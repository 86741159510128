.main-cart {

  .cart-empty {
    text-align: center;
    h3 {
      margin: 20px 0 30px;
    }
  }

  .cart-ship {
    text-align: center;
    margin-bottom: 20px;

    a {
      @include border-radius(7px);
      @include transition(all 0.3s ease-in-out);
      @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
              radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
      @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s);

      color: $navigation-color-hover;
      background-color: $navigation-background-hover;
      display: inline-block;
      font-weight: 400;
      margin: 0;
      padding: 5px 20px;
      line-height: 1.6;
      text-shadow: 1px 1px 2px $dark-shadow-colour;
      box-shadow: black 2px 2px 5px;

      &.shipbtn {
        font-size: .7em;
        padding: 2px 10px;
        vertical-align: text-bottom;
      }

      &:hover {
        color: darken($navigation-color-hover, 30%);
        box-shadow: black 1px 1px 3px;
      }

      &:before {
        @include border-radius(7px);
      }

      @include media ($medium-screen) {
        font-size: 1.2em;
      }
    }
  }

  td {
    border-top: 1px solid $main-colour;
    border-bottom: 1px solid $main-colour;
    font-size: .8em;
  }

  .cart-totals {
    border-top: 2px solid $main-colour;
    border-bottom: 2px solid $main-colour;
    font-weight: 700;
  }

  .cart-cell {
    text-align: center;
    width: 20%;

    @include media ($small-screen) {
      width: 15%;
      font-size: 1em;
    }

    @include media ($medium-screen) {
      width: 10%;
      font-size: 1.2em;
    }

    input[type="text"] {
      width: auto;
      margin: auto;
    }
  }

  .cart-name {
    width: 20%;

    @include media ($small-screen) {
      width: 25%;
      font-size: 1em;
    }

    @include media ($medium-screen) {
      width: 50%;
      font-size: 1.2em;
    }

    @include media ($large-screen) {
      font-size: 1.4em;
    }
  }

  button {
    @include border-radius(7px);
    @include transition(all 0.3s ease-in-out);
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
            radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
    @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s);

    color: $navigation-color-hover;
    background-color: $navigation-background-hover;
    font-weight: 400;
    margin: 0;
    padding: 5px 5px;
    //height: 25px;
    line-height: 1;
    text-shadow: 1px 1px 2px $dark-shadow-colour;
    box-shadow: black 2px 2px 5px;

    &.del {
      padding: 2px 5px;
    }

    svg {
      height: 15px;
      @include transition(all 0.3s ease-in-out);

      fill: darken(white, 10%);
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, .8));
    }

    @include media ($medium-screen) {
      padding: 5px 7px;

      svg {
        height: 19px;
      }

      &.del {
        padding: 2px 7px;
      }
    }

    @include media ($large-screen) {
      padding: 5px 15px;

      &.del {
        padding: 3px 10px;
      }
    }

    &:hover {
      color: darken($navigation-color-hover, 30%);
      box-shadow: black 1px 1px 3px;

      svg {
        fill: white;
        filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .5));
      }
    }

    &:before {
      @include border-radius(7px);
    }

  }

  .ordercheck {
    h2 {
      font-size: 1.7em;
    }
  }

  .cardpay {
    text-align: center;

    img {
      box-shadow: none;
    }

    .paylogo {
      display: block;
    }

    .stripe-logo {
      margin: 20px 0 30px 20px;
      max-height: 40px;
    }

  }

  .outofarea {
    text-align: center;
    font-size: 1.2em;
    color: red;
    margin: 10px 0;
  }

  .shipnotes {
    margin-top: 10px;
    color: red;
    font-size: .9em;
  }

  .security-info {
    @include border-radius($border-radius);
    @include border-color(lighten($alt-colour, 20%));
    @include border-width(1px);
    @include border-style(solid);

    padding: 10px 10px 0;
    margin-bottom: 20px;
    line-height: 1.2;
    box-shadow: black 1px 1px 3px;
    background-color: lighten($alt-colour, 40%);

    @include media ($medium-screen) {
      line-height: 1.4;
    }
  }
}
