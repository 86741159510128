.contact {
  .contact-col {

    @include media ($large-screen) {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  .contactform {

    .contact-form {
      display: block;
      margin-left: auto;
      margin-right: auto;

      .formgroup {
        display: block;

        @include media ($large-screen) {

          label {
            display: inline-block;
            text-align: right;
            width: 25%;

            @include media ($full-screen) {
              font-size: 1.2em;
            }
          }

          .messagelabel {
            vertical-align: top;
            padding-top: 1em;
          }

          #name, #email, #phonenum, #company, #subject, #message {
            display: inline-block;
            width: 70%
          }
        }
      }

      .disp-error {
        color: red;
        text-shadow: 1px 1px 1px $dark-shadow-colour;

        @include media ($large-screen) {
          margin-left: 30%;
        }
      }

      .buttons {
        text-align: right;

        .submit-btn {
          @include border-radius(5px);
          @include border-color($main-colour);
          @include border-width(1px);
          @include border-style(solid);
          @include transition(all 0.3s ease-in-out);
          @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
                  radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
          @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s );

          cursor: pointer;
          color: $navigation-color-hover;
          background-color: $navigation-background-hover;
          font-weight: 400;
          margin: 5px 0;
          padding: 10px 20px;
          display: inline-block;
          line-height: 1;
          text-shadow: 1px 1px 2px $dark-shadow-colour;
          box-shadow: black 2px 2px 7px;
          margin-right: 10%;

          &:hover {
            color: darken($navigation-color-hover, 15%);
            font-weight: 500;
            text-shadow: 1px 1px 1px #ccc;
            box-shadow: black 1px 1px 3px;
          }
        }
      }
    }
  }
}
