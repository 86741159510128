.products {
  .searchres {
    text-align: center;
  }

  .pagination {

    a {
      color: $main-colour;
      font-weight: 600;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);

      &:hover {
        color: darken($main-colour, 10%)
      }
    }

    div {
      display: inline-block;
      padding: 0 2px;
      vertical-align: middle;
      line-height: 1.1;
    }

    span {
      display: block;
    }

    @include media ($medium-screen) {
      span {
        display: inline-block;
      }
    }
  }

  .pagination,
  .jumpperpage,
  .jump-order,
  .jumpgoto {
    text-align: center;

    @include media ($large-screen) {
      @include span-columns(6);
      @include omega(2n);
    }

    label,
    select {
      display: inline-block;
      margin: 3px 0;
    }
  }

  .bottom-pagination {
    .pagination {
      @include span-columns(12);
      @include omega(1);
      margin: -5px 0 10px;
    }
  }

  .item {
    @include border-radius($border-radius);

    margin: 10px 0;
    box-shadow: black 2px 2px 7px;
  }

  .itembody {
    @include clearfix;
    @include border-bottom-radius($border-radius);
    @include border-color(lighten($alt-colour, 15%));
    @include border-width($border-size);

    border-top-style: none;
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    padding: 5px;

    @include media ($large-screen) {
      p {
        font-size: 1.2em;
        line-height: 1.2;
      }
    }
  }

  .piccont {
    @include media ($medium-screen) {
      @include span-columns(4);
      @include omega(2n);
    }
  }

  .specs {
    @include media ($medium-screen) {
      @include span-columns(8);
      @include omega(2n);
    }
  }

  .qtyavail {
    h5 {
      font-weight: 400;
    }
  }

  .purchase {
    text-align: right;
    min-height: 30px;

    a {
      @include border-radius(7px);
      @include transition(all 0.3s ease-in-out);
      @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
              radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
      @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s);

      color: $navigation-color-hover;
      background-color: $navigation-background-hover;
      font-size: 1em;
      font-weight: 400;
      padding: 5px 25px;
      margin: 0 25px 10px;
      text-shadow: 1px 1px 2px $dark-shadow-colour;
      box-shadow: black 2px 2px 5px;

      &:hover {
        color: darken($navigation-color-hover, 30%);
        box-shadow: black 1px 1px 3px;
      }

      &:before {
        @include border-radius(7px);
      }
    }
  }

  .itemhead {
    @include border-top-radius($border-radius);
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .1) 0%, hsla(0, 100%, 100%, .3) 100%, transparent 2%),
            radial-gradient(darken($alt-colour, 5%), darken($alt-colour, 15%)));

    background-color: $alt-colour;

    a {
      color: $main-colour;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
      font-weight: 300;

      &:hover {
        color: lighten($main-colour, 20%);
      }
    }

    h2 {
      margin: 0;
      padding: 5px;
      line-height: 1;
    }
  }

  .itemname {
    text-align: center;
  }

  .itemprice {
    text-align: center;

    a {
      color: lighten($main-colour, 30%);
      font-size: 1.8em;
    }
  }

  .piccont {
    text-align: center;
  }

  .mainpic {
    width: 75%;
    position: relative;
    margin: auto;

    .mainimg {
      width: 100%;
    }
  }

  .specialoffer {
    img {
      position: absolute;
      border-radius: $border-radius 0 0 0;
      box-shadow: none;
      top: 0;
      left: 0;
      width: 45%
    }
  }

  .thumbnails {
    img {
      width: 80px;
      margin: 3px;
    }
  }

  .specscol {
    @include span-columns(6);
    @include omega(2n);
  }

  .noresults {
    text-align: center;
    margin: 20px;
  }

  // Group descritions and pictures
  .grouphead,
  .subcathead {
    @include clearfix();

    .groupinfo,
    .subcatinfo {
      @include clearfix();
      @include border-radius($border-radius);

      padding: 10px 10px 0;
      margin-top: 5px;
    }

    .groupinfo {
      background-color: lighten($main-colour, 40%);
      font-size: .9em;
      line-height: 1.2;

      img {
        width: 100px;
      }
    }

    .subcatinfo {
      background-color: lighten($alt-colour, 40%);
      font-size: .8em;

      img {
        width: 80px;
      }
    }

    h3 {
      font-weight: 300;
      color: $alt-colour;
      margin-top: 0;
      margin-bottom: 0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, .50);
    }

    h4 {
      text-align: center;
      color: $main-colour;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 0;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    }

    img {
      float: left;
      margin: 0 20px 10px 0;
    }
  }
}

