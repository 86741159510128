.checkout {
  .custdets {
    margin-left: 10px;

    @include media ($small-screen) {
      margin-left: 20px;
    }

    @include media ($medium-screen) {
      margin-left: 20px;
    }

    @include media ($large-screen) {
      margin-left: 50px;
    }

    p {
      margin: 0;
      line-height: 1.2;
    }
  }

  .cobuttons {
    text-align: center;

    button {
      margin: 10px;
    }
  }

  .outofarea {
    text-align: center;
    font-size: 1.2em;
    color: red;
    margin: 20px 0;
  }

  .pagemessage {
      margin-bottom: 30px;
  }
}