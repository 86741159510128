.cta-panels {

  img {
    @include span-columns(6);
    @include omega(2n);

    @include media($large-screen) {
      @include span-columns(12);
    }
  }
}
.sister-comps {
  max-width: 500px;
  margin: auto;

  img {
    @include span-columns(6);
    @include omega(2);
    border-radius: 0;
    box-shadow: none;
  }
}