// My Addons //
$main-colour: #8fd82f;
$alt-colour: #023cff;
$content-background-color: white;
$body-background-color: $main-colour;
$border-color: $alt-colour;
$border-size: none;
$border-padding: 0;
$border-radius: 7px;
$element-background-color: #DDD;
$side-border-color: black;
$nav-background-color: $main-colour;
$nav-footer-background-color: $main-colour;
$navigation-padding: .6em;
$navigation-background: $nav-background-color;
$navigation-background-hover: $alt-colour;
$navigation-color: $alt-colour;
$navigation-color-hover: white;
$navigation-height: 40px;
$navigation-active-link-color: transparentize(white, 0.1);
$shadow-colour: rgba(lighten($alt-colour, 10), .7);
$mid-shadow-colour: $alt-colour;
$dark-shadow-colour: rgba(darken($alt-colour, 20), .7);
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900,300italic,400italic,100,100italic");

// Typography
$base-font-family: $roboto;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #477dca;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;
$action-color: $alt-colour;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// My Form Addons //
$form-border-color-error: red;
$form-border-color-valid: green;
$form-box-shadow-error: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-error, 5), .7);
$form-box-shadow-valid: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-valid, 5), .7);
