.searchform {
  .searchbox {
    @include transition(all 0.3s ease-in-out);

    position: relative;

    input[type=search] {
      appearance: none;
      box-sizing: border-box;
      display: block;
      margin: 0;
      height: 40px;
      position: relative;
      transition: border-color;
      width: 100%;
    }

    button[type=submit] {
      @include position(absolute, 0 0 0 null);

      background-color: $alt-colour;
      outline: none;
      padding: 10px 10px;
      height: 40px;

      svg {
        height: 20px;
        @include transition(all 0.3s ease-in-out);

        fill: darken(white, 10%);
        filter: drop-shadow(3px 3px 3px rgba(0,0,0, .8));
      }

      &:hover {
        background-color: darken($alt-colour, 10%);

        svg {
          fill: white;
          filter: drop-shadow(2px 2px 3px rgba(0,0,0, .5));
        }
      }
    }
  }

  label {
    margin: 0;
  }
  .tickbox {
    text-align: center;

    label,
    input {
      vertical-align: middle;
      display: inline;
    }
  }
}
