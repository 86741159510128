// Quick Cart
/* this is the parent menu */
#shopping-cart-nav {

  /* make the LI display inline */
  /* it's position relative so that position absolute */
  /* can be used in submenu */
  /* it is NOT a child of the ul below */
  li {
    display: inline-block;
    position: relative;
    z-index: 99999;
    width: 100%;

    @include media ($medium-screen) {
      width: auto;
    }

    .cart-summary {
      vertical-align: middle;
    }
  }
  /* submenu, it's hidden by default */
  /* it is NOT a parent of the li above */
  ul {
    @include border-radius($border-radius);

    position: absolute;
    top: 28px;
    display: none;
    padding: 0 3px;
    background-color: #FFFFFF;
    border: 4px solid $navigation-background-hover;
    font-size: .8em;

    .prod-name {
      @include span-columns(10);

      text-align: left;
    }

    .prod-price {
      @include span-columns(2);
      @include omega();

      text-align: right;
    }

    .left-btn {
      @include span-columns(6);

      text-align: left;

      a {
        font-size: .9em;

        @include media ($medium-screen) {
          margin-left: 20px;
        }

        &:hover {
          color: darken($navigation-color-hover, 30%);
        }
      }
    }

    .right-btn {
      @include span-columns(6);

      text-align: right;

      a {
        font-size: .9em;
        padding: 5px 10px;

        @include media ($medium-screen) {
          margin-right: 20px;
        }

        &:hover {
          color: darken($navigation-color-hover, 30%);
        }
      }
    }

    @include media ($medium-screen) {
      right: -1px;
      width: 600px;
      margin: auto;
      font-size: 1em;
    }

    a.nodisp {
      display: none;
    }

    a {
      text-decoration: none;

      &:hover {
        color: lighten($navigation-background-hover, 15%);
      }
    }
  }
}

.cart-summary {
  display: inline-block;
}

.cart-btn {
  display: inline-block;
  a {
    @include border-radius(7px);
    @include transition(all 0.3s ease-in-out);
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
            radial-gradient(darken($navigation-background-hover, 5%), darken($navigation-background-hover, 20%)));
    @include radial-gradient-animation(darken($navigation-background-hover, 10%), darken($navigation-background-hover, 25%), 0.3s);

    color: $navigation-color-hover;
    background-color: $navigation-background-hover;
    font-size: .8em;
    font-weight: 400;
    margin: 0 0 5px;
    display: inline-block;
    padding: 5px 25px;
    line-height: 1;
    text-shadow: 1px 1px 2px $dark-shadow-colour;
    box-shadow: black 2px 2px 5px;

    &:hover {
      color: darken($navigation-color-hover, 30%);
      box-shadow: black 1px 1px 3px;
    }

    &:before {
      @include border-radius(7px);
    }
  }
}

.nostock {
  color: red;
  font-weight: 600;
}

.oos {
  text-align: center;
  color: red;
  font-weight: 600;
}

// Main Cart

