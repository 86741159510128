body {

  background-image: url('/images/background.png'), linear-gradient(darken($main-colour, 16.6%) 0%, lighten($main-colour, 9.5%) 40%, lighten($main-colour, 9.5%) 60%, darken($main-colour, 16.6%) 100%) !important;
  background-position: center top;
  background-repeat: repeat-y;
  background-color: lighten($main-colour, 9.5%);

  .main {
    @include outer-container;
    background-color: rgba($content-background-color, 1);
    background-position: center top;
    background-repeat: repeat;
    min-height: 100vh;
  }
}

/*
.outer {
  background-image: url('/images/grey-tflock-bg.png') !important;
  background-position: top left;
  background-repeat: repeat;
}
*/

.tags {
  text-align: center;
  display: none;
}

.clearfix {
  @include clearfix;
}

