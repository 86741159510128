footer {
  margin: 0 10px 10px;

  @include media ($large-screen) {
    margin: 0 20px 10px;
  }

  .footer {
    @include clearfix;
    @include border-radius($border-radius);

    box-shadow: black 2px 2px 7px;
    background-color: $nav-footer-background-color;
    color: $navigation-color;
    text-align: center;
    text-shadow: 1px 1px 2px $dark-shadow-colour;
    font-size: .9em;
    font-weight: 300;
    padding-bottom: 10px;

    @include media ($medium-screen) {
      @include background-image(linear-gradient(hsla(0, 100%, 100%, .4) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 70%),
              radial-gradient(darken($navigation-background, 15%), darken($navigation-background, 5%)));

      padding-top: 5px;
    }

    @include media ($large-screen) {
      font-size: 1em;
    }

    p {
      color: #333333;
      text-align: center;
      font-size: .8em;
      margin: 0;

      a {
        color: darken($alt-colour, 10%);
        &:hover {
          color: $navigation-color-hover;
        }
      }

      @include media ($large-screen) {
        @include span-columns(6);
        @include omega();
        font-size: .9em;
      }
    }

    ul {
      margin-bottom: 5px;
    }

    li {
      display: block;

      & span {
        display: none;
      }

      @include media ($medium-screen) {
        display: inline;

        &:first-child {
          margin-left: -10px;
        }

        & span {
          display: inline-block;
        }
      }

      a {
        display: block;
        padding-top: 10px;

        &:hover {
          color: $navigation-color-hover;
          font-weight: 500;
        }

        @include media ($medium-screen) {
          display: inline-block;
          padding: 0;
          width: 30%;
        }
      }
    }
  }
}

.wrapper {
  @include clearfix;
}
