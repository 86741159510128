@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin linear-gradient-animation( $start, $end, $transTime ){
  position: relative;
  z-index: 100;
  &:before {
    background-image: linear-gradient($end, $start);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: opacity $transTime;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

@mixin radial-gradient-animation( $start, $end, $transTime ){
  background-size: 100%;
  position: relative;
  z-index: 100;
  &:before {
    @include background-image(linear-gradient(hsla(0, 100%, 100%, .25) 0%, hsla(0, 100%, 100%, .1) 50%, transparent 50%),
      radial-gradient($start, $end));
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    @include transition(all $transTime ease-in-out);
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
